<template>
  <div id="leadRequestItem" v-if="editItem && editItem.type">
    <hr v-if="isFloor" class="mt-0 mb-0" />
    <card-list-item style="align-items: flex-start">
      <div class="flex" style="width: 100%">
        <div class="flex justify-between" style="width: 100%">
          <span class="text-left mb-1">
            <h5 class="mb-0">
              <span v-if="editItem.name">{{ editItem.name }}</span>
              <span v-else-if="predefinedAssemblyName">{{ predefinedAssemblyName }}</span>
            </h5>
            <div>
              <div v-if="getDimensionValueById(editItem.dimensions, 'fa')" class="text-muted">
                {{ convertToSqFt(getDimensionValueById(editItem.dimensions, 'fa'), true) }}
              </div>
              <div
                v-if="editItem.name === 'Roof' && getDimensionValueById(editItem.dimensions, 'rsa')"
                class="text-muted"
              >
                {{ getDimensionValueById(editItem.dimensions, 'rsa').toFixed(2) }}ft
              </div>
            </div>
          </span>
          <span class="text-left" v-if="editItem.allow_mapping_override">
            <choose
              v-if="assembly && publicHidden"
              placeholder="Choose..."
              :return-array="false"
              schema="assembly:assembly_emphasis"
              :filters="{
                company_id: $store.state.session.company.company_id
              }"
              v-model="assembliesDropDownData"
            />
            <choose
              v-if="assembly && !publicHidden"
              placeholder="Choose..."
              :return-array="false"
              schema="assembly:assembly_emphasis"
              v-model="assembliesDropDownData"
            />

            <span v-else>&nbsp;</span>
          </span>
        </div>
        <Checkbox
          class="radio ml-4 mt-1"
          v-if="editItem.name !== 'Building' && editItem.name !== 'Basement'"
          v-model="donotRemove"
          :selected-value="true"
          :deselected-value="false"
          :value="editItem.removeItem"
        />
      </div>
    </card-list-item>

    <div v-for="(subItem, index) in editItem.items" :key="index" style="margin: 0; padding: 0">
      <LeadRequestProjectItem
        :item="subItem"
        :index="index"
        :defaultAreaMeasure="defaultAreaMeasure"
        :defaultLengthMeasure="defaultLengthMeasure"
        :assemblies="assemblies"
        :defaultRoom="defaultRoom"
        @item-changed="itemChanged"
        :removeItemCb="removeItemCb"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeadRequestProjectItem',
  mixins: [],
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    defaultAreaMeasure: {
      required: true
    },
    defaultLengthMeasure: {
      required: true
    },
    assemblies: {
      required: true
    },
    defaultRoom: {
      required: true
    },
    removeItemCb: {
      required: true
    }
  },
  emits: ['itemChanged'],

  data() {
    return {
      editItem: null,
      assembly: {},
      predefinedAssemblyName: '',
      isDefaultRoomMapped: false,
      publicHidden: 0
    }
  },
  async created() {
    const hidden = (await this.$store.dispatch('Keyvalue/get', 'HidePublicItems')) || 0
    this.publicHidden = hidden
  },
  computed: {
    isFloor() {
      const languageCode = this.$store.state.session.user.localization_language_code
      if (languageCode === 'fr') {
        return (
          this.editItem.name &&
          (this.editItem.name.includes('Étage Principal') ||
            this.editItem.name.includes('Sous sol') ||
            this.editItem.name.includes('Étage'))
        )
      }
      return (
        this.editItem.name &&
        (this.editItem.name.includes('Main floor') ||
          this.editItem.name.includes('Floor ') ||
          this.editItem.name.includes('Basement'))
      )
    },
    isVisibleDimensions() {
      if (!this.editItem || !this.editItem.dimensions) {
        return false
      }
      const dims = this.editItem.dimensions.filter((dim) => dim.value)
      return dims.length > 0
    },
    assembliesDropDownData: {
      get() {
        if (this.editItem.assembly) {
          return this.editItem.assembly.assembly_id
        }
        return null
      },
      set(b) {
        this.editItem.assembly.assembly_id = b
        this.$emit('itemChanged', this.editItem, this.index)
      }
    },
    donotRemove: {
      get() {
        if (this.editItem.removeItem !== this.removeItemCb) {
          return this.editItem.removeItem
        }
        return this.removeItemCb
      },
      set(b) {
        this.editItem.removeItem = b
        this.$emit('itemChanged', this.editItem, this.index)
      }
    }
  },
  watch: {
    removeItemCb(newVal) {
      if (this.editItem.name !== 'Building' && this.editItem.name !== 'Basement') {
        this.editItem.removeItem = newVal
        this.$emit('itemChanged', this.editItem, this.index)
      }
    }
  },
  methods: {
    itemChanged(changedItem, itemIndex) {
      this.editItem.items[itemIndex] = changedItem
      this.$emit('itemChanged', this.editItem, this.index)
    },
    assemblyChanged() {
      this.editItem.assembly = this.assembly
      this.$emit('itemChanged', this.editItem, this.index)
    },
    itemremoveChange() {
      this.editItem.removeItem = true
      this.$emit('itemChanged', this.editItem, this.index)
    },
    getDimensionValueById(dimensions, id) {
      if (!dimensions) {
        return null
      }
      const found = dimensions.find((dim) => dim.id === id)
      if (found) {
        return found.value
      }
      return null
    },
    convertToFt(perimeter) {
      if (!this.defaultLengthMeasure) {
        return null
      }
      return this.format(
        c.convertMeasures(perimeter, 'm', this.defaultLengthMeasure),
        this.defaultLengthMeasure === 'ft' ? 'imperial' : 'number'
      )
    },
    convertToSqFt(area, toInteger = false) {
      if (!this.defaultAreaMeasure) {
        return null
      }
      return `${this.format(
        c.convertMeasures(area, 'm2', this.defaultAreaMeasure),
        'number',
        toInteger
      )} ft2`
    },
    format(value, measure, toInteger = false) {
      if (toInteger) {
        return c.format(Math.round(value), measure)
      }
      return c.format(Math.round(value * 100) / 100, measure)
    },
    async searchAssembly() {
      if (!this.editItem.id) {
        const name = this.prepareName(this.editItem.name)
        const { object } = await this.$store.dispatch('CostType/search', {
          filters: {
            type: 'assembly'
          },
          searchPhrase: name,
          limit: 1
        })
        return c.imm(object)
      }

      const isAssemblyFound = this.assemblies.find((item) => item.assembly_id === this.editItem.id)
      if (isAssemblyFound) {
        return {
          assembly_id: this.editItem.id
        }
      }
      return c.imm(this.defaultRoom)
    },
    prepareName(roomName) {
      const names = roomName.split(' ')
      return encodeURI(names[0])
    }
  },
  async mounted() {
    this.editItem = c.imm(this.item)
    // set all items to not removed state from prop;
    if (this.editItem.name === 'Building') {
      this.editItem = { ...this.editItem, removeItem: true }
    } else {
      this.editItem = { ...this.editItem, removeItem: this.removeItemCb }
    }
    this.$emit('itemChanged', this.editItem, this.index)
    if (this.editItem.allow_mapping_override && (this.editItem.name || this.editItem.id)) {
      this.assembly = await this.searchAssembly()
      this.editItem.assembly = this.assembly
      if (this.defaultRoom.assembly_id === this.editItem.assembly.assembly_id) {
        this.isDefaultRoomMapped = true
      }
      this.$emit('itemChanged', this.editItem, this.index)
    }
    if (this.editItem.id) {
      const predefined = this.assemblies.find(
        (assembly) => assembly.assembly_id === this.editItem.id
      )
      if (predefined && predefined.assembly_name) {
        this.predefinedAssemblyName = predefined.assembly_name
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.card-list-item {
  border-radius: 0 !important;
}

.chevron-btn {
  width: 22px;
  display: inline-block;
  font-size: 22px;
  cursor: pointer;
}
</style>
