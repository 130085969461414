<template>
  <Container v-if="showThankYou" class="center">
    <Checkmark style="margin: 0 auto" :size="100" />
    <h2 class="mt-3">Thank you for requesting a quote.</h2>
    <p>Your quote was created and a notification was sent to the company owner.</p>
  </Container>
  <div v-else>
    <h3>Quote Builder</h3>
    <div v-show="percentComplete > 0" class="mb-3">
      <div class="progress mb-2 wizard-progress" style="height: 10px">
        <div
          class="progress-bar bg-info"
          role="progressbar"
          :style="`width: ${percentComplete}%`"
        ></div>
      </div>
      <div v-if="progressItemsAdded > 0">Added {{ progressItemsAdded }} items to the quote.</div>
      <div v-if="progressSavingQuote">Saving the quote. This may take a while...</div>
    </div>
    <spin-container ref="spinner" :loading="loading">
      <container class="mt-4" v-if="leadData.role && leadData.role === 'company'">
        <card-section>
          <card-list>
            <card-list-field>
              <span>
                Client
                <br />
                <small> Select the client that you are building this quote for. </small>
              </span>

              <choose
                :return-array="false"
                schema="quote:client_id"
                :order="[
                  ['client_count_quotes', 'asc'],
                  ['client_time_last_modified', 'desc']
                ]"
                :validate="{
                  required: true
                }"
                :disabled="leadData.client && leadData.client.client_id"
                @input="validateForm"
                v-model="clientId"
              />
            </card-list-field>
          </card-list>
        </card-section>
      </container>

      <info
        v-if="
          leadRequest && leadRequest.oReference && leadRequest.oReference.allow_mapping_override
        "
      >
        <p>
          We did our best to match your project to pre-configured Bolster assemblies. Before
          proceeding, please review the matches and adjust them as you see fit.
        </p>
      </info>

      <card-section class="mb-0">
        <card-list>
          <div v-if="isCedreo" style="background-color: #d2e9fe">
            <h4 class="mt-3" style="margin-left: 0.8em">Project Dimensions Summary:</h4>
            <ul>
              <li style="display: flex">
                <h5 class="ml-3" style="flex: 4">Total Framing Area:</h5>
                <h5 class="mr-3">{{ convertToSqFt(firstFloorArea + upperFloorArea) }}</h5>
              </li>
              <li class="flex" style="padding-bottom: 5px">
                <h5 class="ml-3" style="flex: 4">Main Floor Area:</h5>
                <h5 class="mr-3">{{ convertToSqFt(firstFloorArea) }}</h5>
              </li>
              <li class="flex" style="padding-bottom: 5px">
                <h5 class="ml-3" style="flex: 4">Upper Floor Area:</h5>
                <h5 class="mr-3">{{ convertToSqFt(upperFloorArea) }}</h5>
              </li>
              <li class="flex" style="padding-bottom: 5px">
                <h5 class="ml-3" style="flex: 4">Basement Area:</h5>
                <h5 class="mr-3">{{ convertToSqFt(basementArea) }}</h5>
              </li>
              <li class="flex" style="padding-bottom: 5px">
                <h5 class="ml-3" style="flex: 4">Roof Area:</h5>
                <h5 class="mr-3">{{ convertToSqFt(totalRoofArea) }}</h5>
              </li>
              <li class="flex" style="padding-bottom: 5px">
                <h5 class="ml-3" style="flex: 4">Garage Area:</h5>
                <h5 class="mr-3">{{ convertToSqFt(garageArea) }}</h5>
              </li>
            </ul>
          </div>

          <div
            class="flex"
            style="padding: 1.5em 1em 1em; border-bottom: 1px solid #adb5bd; background: #f7faf9"
          >
            <h4 style="flex: 4">Project items:</h4>
            <h4>Select all</h4>
            <div style="margin-top: -4px">
              <Checkbox
                class="info ml-2 radio"
                v-model="removeItemCb"
                :selected-value="true"
                :deselected-value="false"
              />
            </div>
          </div>
          <div v-for="(item, index) in items" :key="index" style="overflow-y: scroll; height: 30vw">
            <LeadRequestProjectItem
              :item="item"
              :removeItemCb="removeItemCb"
              :index="index"
              :defaultAreaMeasure="defaultAreaMeasure"
              :defaultLengthMeasure="defaultLengthMeasure"
              :assemblies="assemblies"
              :defaultRoom="defaultRoom"
              @item-changed="itemChanged"
            />
          </div>
        </card-list>
      </card-section>
      <container v-if="formValid" class="mt-4">
        <btn @click="createQuote" class="info round" :disabled="loading"> Create Quote </btn>
      </container>
    </spin-container>
  </div>
</template>

<script>
import { transformLeadRequest, transformerGeneralRoomId } from '../LeadRequest/Transformers'
import LeadRequestProjectItem from '../LeadRequest/LeadRequestProjectItem.vue'

export default {
  mixins: [],
  components: {
    LeadRequestProjectItem
  },

  props: {
    leadRequest: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      generalRoomName: 'General Room',
      formValid: false,
      loading: false,
      defaultAreaMeasure: null,
      defaultLengthMeasure: null,
      defaultRoom: {},
      assemblies: [],
      totalRoofArea: null,
      firstFloorArea: null,
      basementArea: null,
      garageArea: null,
      upperFloorArea: null,
      clientId: null,
      quote: null,
      percentComplete: 0,
      percentStep: 1,
      progressItemsAdded: 0,
      progressSavingQuote: false,
      items: [],
      possibleDimensions: {},
      showThankYou: false,
      itemsCheckBox: true
    }
  },
  computed: {
    isCedreo() {
      return (
        this.leadRequest &&
        this.leadRequest.oReference &&
        this.leadRequest.oReference.format === 'CEDREO'
      )
    },
    leadData() {
      return this.leadRequest && this.leadRequest.oReference ? this.leadRequest.oReference : {}
    },
    removeItemCb: {
      get() {
        return this.itemsCheckBox
      },
      set(b) {
        this.itemsCheckBox = b
      }
    }
  },
  methods: {
    // look at window width
    // window with, height
    // building footprint -- mainfloor area + garage
    // garage
    setLevelAreaInItem() {
      const tempFirstFloorArea = this.items[0].items[0].dimensions.filter(
        (item) => item.id === 'mfarea'
      )[0]
      const tempUpperFloorArea = this.items[0].items[0].dimensions.filter(
        (item) => item.id === 'upfla'
      )[0]
      this.firstFloorArea = tempFirstFloorArea ? tempFirstFloorArea.value : 0
      this.upperFloorArea = tempUpperFloorArea ? tempUpperFloorArea.value : 0
    },
    setBasementArea() {
      const tempArea = this.items[0].items[0].dimensions.filter((item) => item.id === 'bfa')
      this.basementArea = tempArea[0] ? tempArea[0].value : 0
    },
    setGaArea() {
      const tempArea = this.items[0].items[0].dimensions.filter((item) => item.id === 'garea')
      this.garageArea = tempArea[0] ? tempArea[0].value : 0
    },
    format(value, measure, toInteger = false) {
      if (toInteger) {
        return c.format(Math.round(value), measure)
      }
      return c.format(Math.round(value * 100) / 100, measure)
    },
    convertToSqFt(area, toInteger = false) {
      if (!this.defaultAreaMeasure) {
        return null
      }
      return `${this.format(
        c.convertMeasures(area, 'm2', this.defaultAreaMeasure),
        'number',
        toInteger
      )} ft2`
    },
    validateForm() {
      this.formValid = this.clientId && this.items.length > 0 && this.isItemsValid(this.items)
    },
    isItemsValid(items) {
      return items.every((item) => this.isItemValid(item))
    },
    isItemValid(item) {
      if (item.allow_mapping_override && (!item.assembly || !item.assembly.assembly_id)) {
        return false
      }
      if (!item.items || item.items.length === 0) {
        return true
      }
      return this.isItemsValid(item.items)
    },
    calcPercentStep(items) {
      const countItems = this.calcCountItems(items)
      this.percentStep = 20 / countItems
    },
    calcCountItems(items, countItemsRemoved = 0) {
      const foundItems = items.filter((it) => it.type && it.type === 'assembly')
      let addCountItems = foundItems.length
      // check  if we sit the item to be removed
      if (foundItems.removeItem) {
        addCountItems = 0
      }
      let addChildrenCountItems = 0
      foundItems.forEach((it) => {
        if (it.items) {
          // check if we sit the item to be removed
          if (it.removeItem) {
            addChildrenCountItems += this.calcCountItems(it.items, countItemsRemoved)
          } else {
            this.calcCountItems(it.items, countItemsRemoved)
          }
        }
      })
      return addChildrenCountItems + addCountItems
    },
    async createQuote() {
      try {
        this.progressItemsAdded = 0
        this.progressSavingQuote = false
        this.calcPercentStep(this.items)
        this.loading = true
        this.percentComplete = 1
        c.scrollTo(document.querySelector('.scroll-container--inner'))
        const embue = {
          quote_status: 'p',
          quote_name: this.leadData.quote_name
        }
        if (this.leadData.projectUrl) {
          embue.quote_notes = `Project Url: ${this.leadData.projectUrl}`
        }
        this.quote = await this.createQuoteCall(embue)
        this.percentComplete = 3

        const { refId } = await this.$store.dispatch('Quote/selectExisting', {
          id: this.quote.quote_id,
          type: 'Quote',
          leadRequestId: this.leadData.role === 'client' ? this.leadRequest.lead_request_id : null
        })
        await this.generateItemsForQuote({
          parentRefId: refId,
          items: this.items
        })
        this.progressSavingQuote = true
        await this.$store.dispatch('Quote/auditDependencies', {
          refId,
          immediate: true,
          force: true,
          queue: false
        })

        const norm = _.immutable(this.$store.state.Quote.normalized)
        await this.$store.dispatch('ajax', {
          path: 'lead_request/saveNormalizedQuote',
          data: {
            lead_request_id:
              this.leadData.role === 'client' ? this.leadRequest.lead_request_id : null,
            quote_id: this.quote.quote_id,
            normalized: norm,
            changes: [],
            explicitChanges: []
          },
          queue: false
        })

        if (this.leadData.role === 'client') {
          await this.$store.dispatch('ajax', {
            path: 'lead_request/notifyQuoteOwner',
            data: {
              quote_id: this.quote.quote_id,
              lead_request_id: this.leadRequest.lead_request_id
            }
          })

          this.showThankYou = true
          this.loading = false
          this.percentComplete = 0
        }
        await this.$store.dispatch('ajax', {
          path: 'lead_request/markCompleted',
          data: {
            lead_request_id: this.leadRequest.lead_request_id
          }
        })
        if (this.leadData.role === 'company') {
          await this.$router.push(
            `/${this.$route.params.scopeRoute}/quote/${this.quote.quote_id}?tab=Estimate&confirmDimensions=1`
          )
        }
      } catch (e) {
        await this.$store.dispatch(
          'alert',
          {
            error: true,
            message: e.userMessage || e.message
          },
          { root: true }
        )
        this.loading = false
        this.percentComplete = 0
      }
    },
    async createQuoteCall(embue) {
      if (this.leadData.role === 'client') {
        const { object: quote } = await this.$store.dispatch('ajax', {
          path: 'lead_request/clientCreateQuote',
          data: {
            lead_request_id: this.leadRequest.lead_request_id,
            embue
          }
        })
        return quote
      }
      return this.$store.dispatch('Client/quote', {
        go: false,
        id: this.clientId,
        embue
      })
    },
    async generateItemsForQuote({ parentRefId, items }) {
      return Promise.all(
        items.map(async (item) => {
          if (item.isPrebuilt || (!item.name && !item.id && !item.assembly)) {
            return null
          }
          const newItemPayload = {
            parent: parentRefId,
            type: 'assembly'
          }
          if (item.id || item.assembly) {
            newItemPayload.id = item.assembly ? item.assembly.assembly_id : item.id
          } else {
            newItemPayload.id = 'blank'
          }
          let addedChildrenRefIdsContainer = null
          // check if item has a remove flag childrenrefids;
          if (item.removeItem) {
            const { addedChildrenRefIds } = await this.addAssemblies({
              payload: newItemPayload,
              parentRefId
            })
            addedChildrenRefIdsContainer = addedChildrenRefIds
          }
          const neededChanges = this.prepareItemChanges(item)
          if (Object.keys(neededChanges).length > 0 && addedChildrenRefIdsContainer) {
            await this.addChanges({
              refId: addedChildrenRefIdsContainer[0],
              changes: neededChanges
            })
          }

          if (item.items) {
            // Checking if the predefined assembly has children that needed
            // to update their dimensions or quantity
            const predefinedChildrenWithDimensions = item.items.filter(
              (child) =>
                child.isPrebuilt &&
                child.id &&
                ((child.dimensions && child.dimensions.length > 0) || (child.qty && child.qty > 0))
            )
            if (predefinedChildrenWithDimensions.length > 0) {
              const norm = _.immutable(this.$store.state.Quote.normalized)
              await Promise.all(
                predefinedChildrenWithDimensions.map(async (child) => {
                  const foundNormAssembly = Object.entries(norm).find(
                    (normItem) => normItem[1].assembly_id === child.id
                  )
                  if (!foundNormAssembly || !foundNormAssembly[0]) {
                    return
                  }
                  const neededChildChanges = this.prepareItemChanges(child)
                  if (Object.keys(neededChildChanges).length > 0) {
                    await this.addChanges({
                      refId: foundNormAssembly[0],
                      changes: neededChildChanges
                    })
                  }
                })
              )
            }
            // if item has been removed and its an category
            // aka addedChildrenRefIdsContainer exists
            if (addedChildrenRefIdsContainer) {
              await this.generateItemsForQuote({
                parentRefId: addedChildrenRefIdsContainer[0],
                items: item.items
              })
            } else {
              await this.generateItemsForQuote({
                parentRefId,
                items: item.items
              })
            }
          }
          return true
        })
      )
    },
    async addAssemblies({ parentRefId, payload }) {
      if (payload.length === 0) {
        return { addedChildrenRefIds: [] }
      }
      const { object } = await this.$store.dispatch('Quote/getQuoteItem', payload)
      const result = await this.$store.dispatch('Quote/addChildren', {
        refId: parentRefId,
        children: [object],
        skipAudit: true
      })

      this.progressItemsAdded += 1
      this.percentComplete += this.percentStep

      return result
    },
    async addChanges({ refId, changes }) {
      await this.$store.dispatch('Quote/field', {
        changes,
        explicit: false,
        refId,
        skipAudit: true,
        skipLocalAudit: true
      })
      return true
    },
    prepareItemChanges(item) {
      const changes = {}
      if (item.name && item.name !== '') {
        changes.assembly_name = item.name
      }
      if (item.qty && item.qty > 1) {
        changes.quote_qty_net = item.qty
        changes.quote_qty_net_base = item.qty
      }
      // check if item has dimensions and if it was set to not be built
      if (item.dimensions && item.dimensions.length > 0 && item.removeItem) {
        const dimChanges = {}
        item.dimensions.forEach((dim) => {
          dimChanges[dim.id] = {
            ...this.possibleDimensions[dim.id]
          }
          if (dim.value && dim.unit) {
            dimChanges[dim.id] = {
              ...dimChanges[dim.id],
              value: c.convertMeasures(dim.value, dim.unit, this.getDefaultMeasureByUnit(dim.unit)),
              measure: this.getDefaultMeasureByUnit(dim.unit)
            }
          }
          if (dim.inherit) {
            dimChanges[dim.id] = {
              ...dimChanges[dim.id],
              inherit: dim.inherit
            }
          }
        })
        changes.oDimensions = dimChanges
      }
      return changes
    },
    getDefaultMeasureByUnit(unit) {
      if (unit === 'count') {
        return 'count'
      }
      if (unit === 'm2') {
        return this.defaultAreaMeasure
      }
      return this.defaultLengthMeasure
    },

    itemChanged(changedItem, itemIndex) {
      this.items[itemIndex] = changedItem
      this.validateForm()
    },
    async getAssemblies() {
      const { set } = await this.$store.dispatch('CostType/search', {
        filters: {
          type: 'assembly'
        },
        limit: 10000
      })
      this.assemblies = set

      if (this.defaultRoom.assembly_id) {
        return
      }
      const generalRoom = this.assemblies.find(
        (assembly) => assembly.assembly_name === this.generalRoomName
      )
      this.defaultRoom = generalRoom
    }
  },
  async mounted() {
    this.loading = true
    await this.getAssemblies()

    if (this.leadData.client && this.leadData.client.client_id) {
      this.clientId = this.leadData.client.client_id
    }

    try {
      const lCode = this.$store.state.session.user.localization_language_code
      this.items = transformLeadRequest(this.leadData, lCode)
      if (this.isCedreo) {
        this.setLevelAreaInItem()
        this.setBasementArea()
        this.setGaArea()
        this.totalRoofArea = this.leadRequest.oReference.payload.tableArea.roofFootprintArea || 0

        if (!this.leadData.payload.roof) {
          this.defaultRoom = {
            ...this.defaultRoom,
            assembly_id: transformerGeneralRoomId(this.leadData.format)
          }
        }
      }
    } catch (e) {
      await this.$store.dispatch(
        'alert',
        {
          error: true,
          message: 'Error generating a Quote. Please contact support.'
        },
        { root: true }
      )
      console.log(e)
    }

    this.defaultAreaMeasure = this.$store.getters.userMeta.defaultAreaMeasure || 'ft2'
    this.defaultLengthMeasure = this.$store.getters.userMeta.defaultLengthMeasure || 'ft'

    this.loading = false

    this.possibleDimensions = await this.$store.dispatch(
      'Dimension/getPossibleDimensions',
      {},
      { root: true }
    )
    this.validateForm()
  }
}
</script>
