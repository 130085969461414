import cedreoTransformerGeneralRoomId from './AssemblyCreator'
import transformCedreo from './transformCedreo'
import createRoof from './AssemblyCreator/helpers/createRoof'

export const transformLeadRequest = (leadData, lCode) => {
  if (leadData.format && leadData.format.toUpperCase() === 'CEDREO') {
    return transformCedreo(leadData, lCode)
  }
  if (leadData.format && leadData.format.toUpperCase() === 'RENOWORKS') {
    return createRoof(leadData.payload.roof)
  }
  return null
}

export const transformerGeneralRoomId = (format) => {
  if (format.toUpperCase() === 'CEDREO') {
    return cedreoTransformerGeneralRoomId()
  }
  return null
}
