import { GENERAL_ROOM_ID, roomTypes, prebuiltChildren } from './AssemblyCreator/constants'
import {
  calculateProjectDimensions,
  numOpenings,
  createBuilding,
  createGeneralBuilding,
  windowMeasurements,
  numofWindows
} from './AssemblyCreator/helpers'

const mapRoomType = (roomType) => {
  if (!(roomType in roomTypes) || !roomTypes[roomType]) {
    return GENERAL_ROOM_ID
  }
  return roomTypes[roomType]
}

const transformProjectToItems = ({ project, allow_mapping_override }, lCode) => {
  let mainFloorString = 'Main Floor'
  let mainFloorId = null
  let floorString = 'Floor'
  let numWindows = null
  if (lCode === 'fr') {
    mainFloorString = 'Étage Principal'
    floorString = 'Étage'
  }
  numWindows = numofWindows(project.openings, 'window')
  const items = []
  // gets total width and height of all windows
  const totalWindowMeasurements = windowMeasurements(project.openings, 'window')
  // Building
  const building = createBuilding(project)
  // General Building
  const generalBuilding = createGeneralBuilding(project)
  if (numWindows > 0) {
    generalBuilding.dimensions.push(
      {
        id: 'win',
        unit: 'count',
        value: numWindows
      },
      {
        id: 'windowHeight',
        unit: 'm',
        value: totalWindowMeasurements.height
      },
      {
        id: 'windowWidth',
        unit: 'm',
        value: totalWindowMeasurements.width
      }
    )
  }

  // Predefined General Rooms Build
  let countInternalDoors =
    numOpenings(project.openings, 'PORTE') + numOpenings(project.openings, 'door')
  const countFrontDoors =
    numOpenings(project.openings, 'PORTE_ENTREE') + numOpenings(project.openings, 'front')
  const countGarageDoors = numOpenings(project.openings, 'garage')
  countInternalDoors = countInternalDoors - countGarageDoors - countFrontDoors
  // if (numWindows > 0) {
  generalBuilding.dimensions.push(
    {
      id: 'indor',
      unit: 'count',
      value: countInternalDoors
    },
    {
      id: 'win',
      unit: 'count',
      value: numWindows
    }
  )
  // }
  generalBuilding.items.push({
    isPrebuilt: true,
    id: prebuiltChildren.GENERAL_ROOM_BUILD_ID,
    dimensions: [
      {
        id: 'fa',
        inherit: 1
      },
      {
        id: 'iwp',
        unit: 'm',
        inherit: 1
      }
    ]
  })

  building.items = [generalBuilding]
  // const level0Type = project.levels[0].levelType;
  let level1Fa = null
  let level2Fa = null
  project.levels.forEach((lvl, levelIndex) => {
    // if (level0Type === 'TYPE_BASEMENT') {
    //   newLevelIndex = levelIndex + 1;
    // } else {
    //   newLevelIndex = levelIndex;
    // }
    /**
     * check levels add save each levels fa
     */
    if (levelIndex === 1) {
      level1Fa = lvl.fa
      mainFloorId = lvl.modelUuid

      generalBuilding.dimensions.push({
        id: 'mfarea',
        unit: 'm2',
        inherit: 0,
        value: lvl.fa
      })
    }
    if (levelIndex === 2) {
      level2Fa = lvl.fa
      generalBuilding.dimensions.push({
        id: 'upfla',
        unit: 'm2',
        inherit: 0,
        value: lvl.fa
      })
    }
    let level = {
      type: 'assembly',
      name: levelIndex === 0 ? mainFloorString : `${floorString} ${levelIndex}`,
      allow_mapping_override: false,
      dimensions: []
    }
    level.dimensions = [
      {
        id: 'fa',
        unit: 'm2',
        value: lvl.fa
      },
      {
        id: 'iwp',
        unit: 'm',
        value: lvl.iwp
      },
      {
        id: 'iwh',
        unit: 'm',
        value: lvl.iwh
      }
    ]
    if (lvl.levelType === 'TYPE_BASEMENT') {
      generalBuilding.dimensions.push({
        id: 'bfa',
        unit: 'm2',
        value: project.basementArea
      })
    }
    if (lvl.levelType === 'TYPE_BASEMENT' && lCode === 'en') {
      level = {
        type: 'assembly',
        name: 'Basement',
        allow_mapping_override: false,
        dimensions: [
          {
            id: 'fa',
            unit: 'm2',
            value: project.basementArea
          }
        ]
      }
    }
    if (lvl.levelType === 'TYPE_BASEMENT' && lCode === 'fr') {
      level = {
        type: 'assembly',
        name: 'Sous sol',
        allow_mapping_override: false,
        dimensions: [
          {
            id: 'fa',
            unit: 'm2',
            value: project.basementArea
          }
        ]
      }
    }

    level.items = lvl.rooms.map((room) => {
      if (room.name.toLowerCase().includes('garage')) {
        generalBuilding.dimensions.push({
          id: 'garea',
          unit: 'm2',
          value: room.area
        })
      }
      const iwh = {
        id: 'iwh'
      }
      if (room.ceilingHeight && room.ceilingHeight === lvl.iwh) {
        iwh.inherit = 1
      } else {
        iwh.value = room.ceilingHeight
        iwh.measure = 'm'
      }
      const roomPayload = {
        type: 'assembly',
        name: room.name,
        id: room.ref ? mapRoomType(room.ref) : null,
        allow_mapping_override,
        dimensions: [
          {
            id: 'fa',
            unit: 'm2',
            value: room.area
          },
          {
            id: 'iwp',
            unit: 'm',
            value: room.perimeter
          },
          iwh
        ]
      }
      const countGarageDoorsWall = numOpenings(project.openings, 'garage', room.wallsId)
      if (countGarageDoorsWall > 0) {
        roomPayload.dimensions.push({
          id: 'gdor',
          unit: 'count',
          value: countGarageDoorsWall
        })
      }
      return roomPayload
    })

    if (lvl.commonSpace) {
      const commonSpace = {
        type: 'assembly',
        name: 'Common Space',
        allow_mapping_override: false
      }
      const iwh = {
        id: 'iwh'
      }
      if (lvl.commonSpace.iwh && lvl.commonSpace.iwh === lvl.iwh) {
        iwh.inherit = 1
      } else {
        iwh.value = lvl.commonSpace.iwh
        iwh.measure = 'm'
      }
      commonSpace.dimensions = [
        {
          id: 'fa',
          unit: 'm2',
          value: lvl.commonSpace.fa
        },
        {
          id: 'iwp',
          unit: 'm',
          value: lvl.commonSpace.iwp
        },
        iwh
      ]
      level.items.push(commonSpace)
    }
    const ecwp = project.walls
      .map((wall) => {
        if (Number(wall.thickness.toFixed(1)) === 0.3 && wall.levelUuid === mainFloorId) {
          return wall.thickness * wall.length
        }
        return 0
      })
      .reduce((prev, next) => prev + next)
    generalBuilding.dimensions.push(
      {
        id: 'tfa',
        unit: 'm2',
        value: level2Fa + level1Fa
      },
      {
        id: 'ra',
        unit: 'm2',
        value: project.tableArea.roofFootprintArea
      }
    )
    if (ecwp && !generalBuilding.dimensions.filter((el) => el.id === 'ecwp').length) {
      generalBuilding.dimensions.push({
        id: 'ecwp',
        unit: 'm',
        value: ecwp
      })
    }
    //

    // Adding levels into New House General Construction
    building.items[0].items.push(level)
  })

  building.items = [generalBuilding]
  items.push(building)

  return items
}

export default function transformCedreo(leadData, lCode) {
  const { payload, allow_mapping_override } = leadData
  const project = calculateProjectDimensions(payload)

  return transformProjectToItems({ project, allow_mapping_override }, lCode)
}
