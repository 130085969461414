const createBuilding = (project) => ({
  type: 'assembly',
  name: 'Building',
  allow_mapping_override: false,
  dimensions: [
    {
      id: 'fa',
      unit: 'm2',
      value: project.fa
    },
    {
      id: 'fp',
      unit: 'm2',
      value: project.fp
    },
    {
      id: 'iwp',
      unit: 'm',
      value: project.iwp
    },
    {
      id: 'iwh',
      unit: 'm',
      value: project.iwh
    },
    {
      id: 'foundp',
      unit: 'm',
      value: project.foundp
    },
    {
      id: 'slabh',
      unit: 'm',
      value: project.slabh
    },
    {
      id: 'ewh',
      unit: 'm',
      value: project.ewh
    },
    {
      // total framing area
      id: 'tfa',
      unit: 'm2'
    },
    {
      id: 'ewp',
      unit: 'm',
      value: project.ewp
    },
    {
      // total framing roof area
      id: 'ra',
      unit: 'm2'
    },
    {
      // External concrete wall perimeter
      id: 'bfa',
      unit: 'm2',
      value: project.basementArea
    }
  ]
})
export default createBuilding
