export const HOUSE_REF_ID = 'as-4992-to-global-on-1637185781-as-u2owz21gl19' // 'as-2ynomqtko2sz';
export const GENERAL_ROOM_ID = 'as-4992-to-global-on-1637185770-as-2fifpb8inmxv'
export const GENERAL_ROOF_ID = 'as-yavri1tl2e8kw'
// General House Build: 'as-4992-to-global-on-1637185781-as-4mtw4g2079ln'

export const roomTypes = {
  // Bathroom
  SALLEDEAU: 'as-4992-to-global-on-1637185781-as-6j7hq6k00zl7',
  SALLEDEBAIN: 'as-4992-to-global-on-1637185781-as-6j7hq6k00zl7',
  WW_SALLE_DE_BAIN: 'as-4992-to-global-on-1637185781-as-6j7hq6k00zl7',

  // Balcony
  BALCON: '',
  WW_BALCON: '',

  // Basement
  SOUSSOL: '',

  // Bedroom
  CHAMBRE: '',
  WW_CHAMBRE: '',

  // Cellar
  CELLIER: '',
  CELLIER_ISOLE: '',
  WW_CELLIER: '',

  // Clearance
  DEGAGEMENT: '',
  WW_DEGAGEMENT: '',

  // Cloakroom
  VESTIAIRE: 'as-4992-to-global-on-1637185772-as-2fifpb8iw6dn',

  // Closet
  PLACARD: 'as-4992-to-global-on-1637185774-as-2fifpb8iw8ap',
  WW_PLACARD: 'as-4992-to-global-on-1637185774-as-2fifpb8iw8ap',

  // Deck
  TERRASSE: 'as-86za6f6161z',

  // Dining room
  WW_SALLE_A_MANGER: '',

  // Dressing room
  DRESSING: 'as-4992-to-global-on-1637185772-as-2fifpb8iw6dn',
  WW_DRESSING: 'as-4992-to-global-on-1637185772-as-2fifpb8iw6dn',

  // Empty
  'VIDE SUR HALL': '',
  WW_VIDE: '',

  // Entry
  ENTREE: '',
  WW_ENTREE: '',

  // Garage
  GARAGE: 'as-1d7xuf4cxqdl',
  WW_GARAGE: 'as-1d7xuf4cxqdl',

  // Garden
  JARDIN: '',
  WW_JARDIN: '',

  // hopper
  TREMIE: '',
  WW_TREMIE: '',

  // Kitchen
  CUISINE: 'as-4992-to-global-on-1637185781-as-58plr8p1zcug',
  WW_CUISINE: 'as-4992-to-global-on-1637185781-as-58plr8p1zcug',

  // Landing
  PALIER: '',

  // Laundry
  LINGERIE: 'as-4992-to-global-on-1637185771-as-2fifpb8iw1me',
  BUANDERIE: 'as-4992-to-global-on-1637185771-as-2fifpb8iw1me',

  // Living room
  PIECEDEVIE: '',
  SEJOUR: '',
  SEJOUR_SALON: '',
  SALON: '',
  WW_SEJOUR: '',

  // Lobby
  HALL: '',

  // Lumicene
  LUMICENE: '',

  // Master bedroom
  SUITE_PARENTALE: '',
  WW_SUITE_PARENTALE: '',

  // Mezzanine
  MEZZANINE: '',

  // Office
  BUREAU: '',
  WW_BUREAU: '',

  // Patio
  PATIO: 'as-86za6f60un5',

  // Playroom
  SALLE_DE_JEUX: '',
  WW_SALLE_DE_JEUX: '',

  // Porch
  PORCHE: '',
  WW_PORCHE: '',

  // Recoverable attics
  COMBLE_RECUPERABLES: '',

  // Roof terrace (accessible)
  'TOITURE TERRASSE ACCESSIBLE': '',

  // Roof terrace (inaccessible)
  'TOITURE TERRASSE NON ACCESSIBLE': '',

  // Technical room (area)
  GAINE_TECHNIQUE: '',
  'LOCAL TECHNIQUE': '',
  WW_LOCAL_TECHINQUE: '',

  // Terrace
  WW_TERRASSE: '',

  // Under stairs
  WW_SOUS_ESCALIER: '',

  // Toilet
  WC: 'as-4992-to-global-on-1637185781-as-6j7hq6k01ab9',
  WW_WC: 'as-4992-to-global-on-1637185781-as-6j7hq6k01ab9',

  // Traditional carpentry
  'CHARPENTE TRADITIONNELLE': '',

  // Under stairs
  'SOUS ESCALIER': '',

  // Workshop
  ATELIER: ''
}
export const prebuiltChildren = {
  FOUNDATION: 'as-4992-to-global-on-1637185781-as-86za6f0947g',
  HOUSE_FRAMING: 'as-4992-to-global-on-1637185781-as-86za6f0aazj',
  DRYWALL: 'as-4992-to-global-on-1637185766-as-1ahh52jbjffz',
  GENERAL_ROOM_BUILD_ID: 'as-4992-to-global-on-1637185769-as-2cryzynmsq2p'
}
