const numOpenings = (projectOpenings, openingName, wallsId = []) => {
  let openings = projectOpenings.filter((open) =>
    (open.ref || '').toLowerCase().includes(openingName.toLowerCase())
  )
  if (wallsId.length > 0) {
    openings = openings.filter((open) => wallsId.includes(open.wallId))
  }
  return openings.length
}

const numofWindows = (projectOpenings, openingName, wallsId = []) => {
  let openings = projectOpenings.filter((open) =>
    (open.openingType || '').toLowerCase().includes(openingName.toLowerCase())
  )
  if (wallsId.length > 0) {
    openings = openings.filter((open) => wallsId.includes(open.wallId))
  }
  return openings.length
}
const windowMeasurements = (projectOpenings, openingName) => {
  let totalWidth = null
  let totalHeight = null
  const openings = projectOpenings.filter((open) =>
    (open.openingType || '').toLowerCase().includes(openingName.toLowerCase())
  )
  totalWidth = openings.map((opening) => opening.width).reduce((prev, next) => prev + next)
  totalHeight = openings.map((opening) => opening.height).reduce((prev, next) => prev + next)
  return { width: totalWidth, height: totalHeight }
}

export { numOpenings, numofWindows, windowMeasurements }
