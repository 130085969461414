export default function calculateProjectDimensions(payload) {
  const project = _.imm(payload)
  let projectEwp = null
  let basementLevelUid = null
  project.slabh = null
  const roofAreaUid = project.levels[project.levels.length - 1].modelUuid
  if (project.levels[0].levelType === 'TYPE_BASEMENT') {
    basementLevelUid = project.levels[0].modelUuid
  }
  project.levels.forEach((level, levelIndex) => {
    const updatedLevel = _.imm(level)
    // Rooms with name
    const levelId = level.id
    updatedLevel.rooms = project.rooms.filter(
      (room) => room.levelId === levelId && room.name && room.name !== ''
    )
    if (updatedLevel.rooms.length > 0 && updatedLevel.levelType !== 'TYPE_BASEMENT') {
      updatedLevel.fa = updatedLevel.rooms
        .map((room) => {
          if (room.areaType !== 'TYPE_AREA_ANNEX') {
            return room.area
          }
          return 0
        })
        .reduce((prev, next) => prev + next)
      updatedLevel.iwp = updatedLevel.rooms
        .map((room) => {
          if (room.areaTableExport && room.perimeter) {
            return room.perimeter
          }
          return 0
        })
        .reduce((prev, next) => prev + next)
      updatedLevel.iwh = updatedLevel.rooms[0].ceilingHeight
    } else {
      updatedLevel.fa = 0
      updatedLevel.iwp = 0
      updatedLevel.iwh = 0
    }

    // Rooms without name
    const commonSpaces = project.rooms.filter(
      (room) => room.levelId === levelId && (!room.name || room.name === '')
    )
    if (commonSpaces.length > 0) {
      updatedLevel.commonSpace = {
        fa: commonSpaces.map((room) => room.area).reduce((prev, next) => prev + next),
        iwp: commonSpaces.map((room) => room.perimeter).reduce((prev, next) => prev + next),
        iwh: commonSpaces[0].ceilingHeight
      }
      if (updatedLevel.iwh === 0) {
        updatedLevel.iwh = commonSpaces[0].ceilingHeight
      }
      updatedLevel.fa += updatedLevel.commonSpace.fa
      updatedLevel.iwp += updatedLevel.commonSpace.iwp
    }
    // get all exterior walls for this level and add them up
    projectEwp =
      project.walls
        .map((wall) => {
          if (
            wall.levelUuid === level.modelUuid &&
            levelId === 1 &&
            Number(wall.thickness) === 0.2
          ) {
            return wall.thickness
          }
          return 0
        })
        .reduce((prev, next) => prev + next) + projectEwp
    project.levels[levelIndex] = updatedLevel
  })
  project.fa = project.levels.map((level) => level.fa || 0).reduce((prev, next) => prev + next)
  project.iwp = project.levels.map((level) => level.iwp).reduce((prev, next) => prev + next)
  project.iwh = project.rooms[0].ceilingHeight
  project.ewh = project.levels.map((level) => level.iwh).reduce((prev, next) => prev + next)
  project.fp = project.tableArea.wallFootprintArea
  project.ewp = projectEwp
  project.basementArea = project.rooms
    .map((room) => {
      if (room.levelUuid === basementLevelUid && room.areaType !== 'TYPE_AREA_ANNEX') {
        return room.area || 0
      }
      return null
    })
    .reduce((prev, next) => prev + next)
  project.roofArea = project.rooms
    .map((room) => {
      if (room.levelUuid === roofAreaUid) {
        return room.area || 0
      }
      return null
    })
    .reduce((prev, next) => prev + next)
  // calculate slabh
  project.walls.forEach((wall) => {
    project.levels[0].rooms[0].wallsUuid.forEach((loopWall) => {
      if (loopWall === wall.modelUuid) {
        project.slabh = Number(wall.thickness)
      }
    })
  })
  if (basementLevelUid) {
    project.foundp = project.walls
      .map((wall) => {
        if (
          wall.levelUuid === basementLevelUid &&
          Number(wall.thickness) === Number(project.slabh)
        ) {
          return wall.length
        }
        return null
      })
      .reduce((prev, next) => prev + next)
  }

  project.fa -= project.basementArea
  return project
}
