import { HOUSE_REF_ID, prebuiltChildren } from '../constants'

const createGeneralBuilding = (project) => ({
  type: 'assembly',
  id: HOUSE_REF_ID,
  name: 'General Building',
  allow_mapping_override: true,
  dimensions: [
    {
      id: 'fa',
      inherit: 1
    },
    {
      id: 'fp',
      inherit: 1
    },
    {
      id: 'ewp',
      inherit: 1
    },
    {
      id: 'iwp',
      inherit: 1
    },
    {
      id: 'iwh',
      inherit: 1
    },
    {
      id: 'oudor',
      unit: 'count',
      value: 1
    },
    {
      id: 'slabh',
      unit: 'ft',
      inherit: 1
    },
    {
      id: 'ewh',
      unit: 'm',
      inherit: 1
    },
    {
      id: 'foundp',
      unit: 'm',
      inherit: 1
    }
  ],
  items: [
    // Prebuilt items defined by isPrebuilt: true
    // {
    //   isPrebuilt: true,
    //   id: prebuiltChildren.FOUNDATION,
    // },
    // House Framing
    {
      isPrebuilt: true,
      id: prebuiltChildren.HOUSE_FRAMING,
      dimensions: [
        {
          id: 'fa',
          inherit: 1
        },
        {
          id: 'fp',
          unit: 'm2',
          value: project.fp
        },
        {
          id: 'iwp',
          unit: 'm',
          value: project.iwp
        }
      ]
    },
    // Drywall
    {
      isPrebuilt: true,
      id: prebuiltChildren.DRYWALL,
      dimensions: [
        {
          id: 'fa',
          inherit: 1
        },
        {
          id: 'fp',
          unit: 'm2',
          value: project.fp
        },
        {
          id: 'iwp',
          unit: 'm',
          value: project.iwp
        },
        {
          id: 'iwh',
          inherit: 1
        }
      ]
    }
  ]
})
export default createGeneralBuilding
