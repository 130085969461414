import { GENERAL_ROOF_ID } from '../constants'

const createRoof = (project) => [
  {
    type: 'assembly',
    name: 'Roof',
    allow_mapping_override: true,
    id: GENERAL_ROOF_ID,
    dimensions: [
      {
        id: 'rsa',
        unit: 'ft',
        value: project.totalArea
      },
      {
        id: 'eaves',
        unit: 'ft',
        value: project.eaves
      },
      {
        id: 'rhip',
        unit: 'ft',
        value: project.hips
      },
      {
        id: 'rridge',
        unit: 'ft',
        value: project.ridges
      },
      {
        id: 'rvalley',
        unit: 'ft',
        value: project.valleys
      },
      {
        id: 'sflash',
        unit: 'ft',
        value: project.stepFlashings
      },
      {
        id: 'Blength',
        unit: 'ft',
        value: project.eaves
      },
      {
        id: 'frwall',
        unit: 'ft',
        value: project.flashings
      }
    ]
  }
]
export default createRoof

// ra  totalArea
// eaves   eaves
// rhip  hips
// rridge ridges
// rvalley  valleys
// sflash stepFlashing
// stepFlashings
